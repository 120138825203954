import WebApp from 'tma-dev-sdk';

export function enableYandexCounters() {
  if (!import.meta.env.DEV) {
    return import('ym.js')
      .then(({ defineYmCounters }) => {
        const counters = defineYmCounters({
          global: {
            id: 94518118,
            enabled: true,
            ymOptions: {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              trackHash: true,
              childIframe: true,
              userParams: {
                UserID: WebApp.initDataUnsafe.user?.id ?? -1,
              },
            },
          },
        });

        console.log('enable yandex metrics');

        counters.global.enable();

        return counters.global;
      })
      .catch(() => undefined);
  }

  return Promise.resolve(undefined);
}
