import { memoize } from 'shared/memoize';

// TODO: get rid of cyclical dependency
import { getCurrentTimezoneOffset } from 'f/settings/timezone';

const toMinutes = (d: Date) => Math.floor((d.getTime() / 1000) / 60);

const datesToMemKey = (d?: Date | null, r?: Date): `${number | undefined}:${number | undefined}:${number}` => (
  `${d?.getTime()}:${r?.getTime()}:${getCurrentTimezoneOffset()}`
);

type WithCallbackVariable<F extends (...args: any) => any> = F & {
  <T>(...args: [...args: Parameters<F>, f: (arg: ReturnType<F>) => NonNullable<T>]): NonNullable<T>;
};

export const withCallbackVariable = <F extends (...args: any) => any>(f: F) => (
  (...args: [...args: Parameters<F>, df: (arg: ReturnType<F>) => unknown]) => {
    const df = args[args.length - 1];

    const result = f(...args);

    if (typeof df !== 'function' || f.length >= args.length) {
      return result;
    }

    return df?.(result) ?? result;
  }
) as WithCallbackVariable<F>;


/**
 * Sets "date" to mean "the date in a configured timezone" but in UTC, to trick js
 *
 * Has no change in time if local timezone is the same as in the config
 *
 * (if ```d.getTimezoneOffset() - getCurrentTimezoneOffset() === 0```)
 * @see {@link fromConfigTimezone} for more
 */
export const withConfigTimezone = withCallbackVariable((d: Date) => {
  const date = new Date(d);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset() - getCurrentTimezoneOffset());
  return date;
});

/**
 * Reverts the change made by `withConfigtimezone`
 *
 * Has no change in time if local timezone is the same as in the config
 *
 * (if ```d.getTimezoneOffset() - getCurrentTimezoneOffset() === 0```)
 * @see {@link withConfigTimezone} for more
 */
export const fromConfigTimezone = withCallbackVariable((d: Date) => {
  const date = new Date(d);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset() + getCurrentTimezoneOffset());
  return date;
});

export const withTime = withCallbackVariable((d: Date, hours: number, minutes: number) => {
  const date = new Date(d);
  date?.setHours(hours, minutes, 1, 1);
  return date;
});

export const withoutTime = (d: Date) => {
  const date = new Date(d);
  // "No time" means 0s:0ms in UTC
  date?.setUTCSeconds(0, 0);
  return date;
};

export const isDateInPast = /* memoize( */(d?: Date | null, relativeTo: Date = today()) => !!d && (
  hasTime(d) // TODO: investigate this weird behavior
    ? toMinutes(d) - getCurrentTimezoneOffset() < toMinutes(relativeTo)
    : d.getUTCFullYear() == relativeTo.getUTCFullYear()
      ? d.getUTCMonth() == relativeTo.getUTCMonth()
        ? d.getUTCDate() < relativeTo.getUTCDate()
        : d.getUTCMonth() < relativeTo.getUTCMonth()
      : d.getUTCFullYear() < relativeTo.getUTCFullYear()
)/* , datesToMemKey) */;

export const isDateEqual = /* memoize( */(d?: Date | null, relativeTo: Date = today()) => !!d && (
  hasTime(d)
    // Move date to a config timezone to properly compare with UTC-fied `today`
    ? withConfigTimezone(d, d =>
      d.getFullYear() == relativeTo.getUTCFullYear()
      && d.getMonth() == relativeTo.getUTCMonth()
      && d.getDate() == relativeTo.getUTCDate()
    ) : (
      d.getUTCFullYear() == relativeTo.getUTCFullYear()
      && d.getUTCMonth() == relativeTo.getUTCMonth()
      && d.getUTCDate() == relativeTo.getUTCDate()
    )
)/* , datesToMemKey) */;

export const today = () => withConfigTimezone(new Date(), d => {
  // Sets "today" to mean "now in a configured timezone" but in UTC,
  // to align with server-side notion of "today"
  d.setUTCFullYear(
    d.getFullYear(),
    d.getMonth(),
    d.getDate()
  );

  // Preserve time, but set to mean "without time"
  return withoutTime(d);
});

export const relativeDate = (deltaDays: number) => (relativeTo = today()) => {
  const date = new Date(relativeTo);
  date.setDate(relativeTo.getDate() + deltaDays);
  return date;
};

export const yesterday = relativeDate(-1);
export const tomorrow = relativeDate(1);
export const afterTomorrow = relativeDate(2);

/**
 * Checks if a date "has no time",
 * i.e. has 0s:0ms and should be taken as a literal day,
 * without the time at all
 */
export const hasNoTime = (date?: Date | null) => !!date && (
  // date.getHours() === 0 &&
  // date.getMinutes() === 0 &&
  date.getSeconds() === 0 &&
  date?.getMilliseconds() === 0
);
/**
 * Checks if a date was set to mean a specific time,
 * i.e. has at least a second or a millisecond in it
 */
export const hasTime = (date?: Date | null) => !!date && (
  // date.getHours() !== 0 ||
  // date.getMinutes() !== 0 ||
  date.getSeconds() !== 0 ||
  date?.getMilliseconds() !== 0
);

export const toRelativeTimeString = (date: Date | null) => {
  return date?.toISOString();
};
