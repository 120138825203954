import { onCleanup, onMount } from 'solid-js';

/**
 * Apple sucks
 */
export const isIOS = () => /iPhone|iPad|iPod/.test(window.navigator.userAgent);
export const isAndroid = () => /Android/.test(window.navigator.userAgent);

export const isInElement = (el: HTMLElement | null, host: HTMLElement | null) => {
  return (function traverse(subEl: HTMLElement | null) {
    if (subEl?.tagName.toLowerCase() === 'dialog') {
      return true;
    }

    if (subEl === null || host === null) {
      return false;
    }

    if (subEl === document.documentElement && host !== document.documentElement) {
      return false;
    }

    if (subEl === host) {
      return true;
    }

    return traverse(subEl?.parentElement ?? null);
  })(el);
};

export const runLater = (cb: () => unknown, delay?: number) => new Promise<void>((res, rej) => (
  setTimeout(() => {
    try {
      cb();
      res();
    } catch (error) {
      rej(error);
    }
  }, delay)
));

export const applyAndroidScrollFix = () => {
  const isInput = (el: Element | null): el is HTMLInputElement | HTMLTextAreaElement => (
    el?.tagName.toLowerCase() == 'input'
    || el?.tagName.toLowerCase() == 'textarea'
  );

  function inputScrollFix() {
    const activeElement = document.activeElement;

    if (isInput(activeElement)) {
      runLater(() => {
        if ('scrollIntoViewIfNeeded' in activeElement && typeof activeElement.scrollIntoViewIfNeeded === 'function') {
          activeElement.scrollIntoViewIfNeeded();
        } else {
          activeElement.scrollIntoView();
        }
      });
    }
  }

  onMount(() => {
    if (isAndroid()) {
      window.addEventListener('resize', inputScrollFix);
    }
  });

  onCleanup(() => {
    window.removeEventListener('resize', inputScrollFix);
  });
};
