import { mapFactory } from 'parakeet-mapper';

import type { components } from 'shared/network/schema';
import { currentLocale } from 'shared/l10n';

import { defaultTimeZone } from './timezone-list';
import { getCurrentTimezone, getTimezone } from './timezone';

export interface ClientTimeZone {
  id: string;
  offset: number;
  offsetName: string;
}

export interface ClientSettings {
  locale: Intl.Locale;
  timeZone: ClientTimeZone;
}

export const toClientSettings = mapFactory<components['schemas']['UTProfileSettingsModel'], ClientSettings>({
  locale: [tag => new Intl.Locale(tag)],
  timeZone: settings => (!settings.timeZoneId || settings.timeZoneId === defaultTimeZone)
    ? getTimezone()
    : getTimezone(settings.timeZone, settings.timeZoneId),
});

export const toServerSettings = mapFactory<Partial<ClientSettings>, Partial<components['schemas']['UTProfileSettingsModel']>>({
  locale: [locale => locale?.baseName ?? currentLocale().baseName],
  timeZone: [timeZone => timeZone?.offset ?? getCurrentTimezone().offset],
  timeZoneId: { timeZone: tz => tz?.id ?? getCurrentTimezone().id },
});
