import { fetchUtasks } from 'shared/network';
import { currentLocale } from 'shared/l10n';

import { getCurrentTimezone, getCurrentTimezoneId, getCurrentTimezoneOffset } from './timezone';
import { toClientSettings, toServerSettings } from './settings.adapter';
import type { ClientSettings } from './settings.adapter';

let settingsFetch: Promise<ClientSettings>;

export const getLatestSettings = () => settingsFetch;

export const fetchSettings = () => (
  settingsFetch = fetchUtasks
    .get('/api/profile/settings')
    .then(r => r.data ?? {
      locale: currentLocale().baseName,
      timeZone: getCurrentTimezoneOffset(),
      timeZoneId: getCurrentTimezoneId(),
    })
    .then(settings => {
      const clientSettings = toClientSettings(settings);

      // A dirty fix to account
      // for back-end's unawareness
      // about user timezone's daylight saving time rules
      // Since user's device knows the right offset for current time,
      // we simply update the offset setting to the correct value,
      // if the set value is different from the right one
      if (settings.timeZone !== clientSettings.timeZone.offset) {
        updateSettings({
          timeZone: clientSettings.timeZone,
        });
      }

      return clientSettings;
    })
    .catch<ClientSettings>(() => ({
      locale: currentLocale(),
      timeZone: getCurrentTimezone(),
    }))
);

export const updateSettings = (settings: Partial<ClientSettings>) => (
  fetchUtasks
    .put('/api/profile/settings', {
      //@ts-expect-error backend will catch-up to typings later
      body: toServerSettings(settings),
    })
    .then(r => r.data)
    .catch(() => undefined)
);

export const fetchDefaultSettings = () => (
  fetchUtasks
    .get('/api/profile/default/settings')
    .then(r => r.data)
    .catch(() => undefined)
);
