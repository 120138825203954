import createClient from 'openapi-fetch';

import { configFetching, getConfigString, app } from 'shared/firebase';

import type { paths } from './schema';

const backendUrlConfigKey = import.meta.env.APP_CONFIG_BACKENDURL ?? 'backurl';

const backendUrl = () => getConfigString(backendUrlConfigKey);

export let resolvedBackendUrl = (import.meta.env.APP_BACKEND ?? 'https://utasks.io/utb');

export const baseConfig = configFetching.then(() => (
  backendUrl().then(newBaseUrl => {
    resolvedBackendUrl = newBaseUrl || resolvedBackendUrl;

    app.then((c) => {
      console.info(`Version: v${import.meta.env.APP_VERSION}:${import.meta.env.APP_BUILD}`);
      console.info(`Environment: ${c.options.projectId}:${new URL(resolvedBackendUrl).hostname}`);
    });

    return {
      baseUrl: resolvedBackendUrl,
    };
  })
));

export const baseFetchUtasks = baseConfig.then(c => createClient<paths>(c));

export type RawBaseClient = typeof baseFetchUtasks extends Promise<infer T> ? T : never;

export type BaseClient = {
  [key in keyof RawBaseClient as Lowercase<key>]: RawBaseClient[key];
};
