import WebApp from 'tma-dev-sdk';
import { merge, tap } from 'rambda';
import type { WebAppInitData } from '@twa-dev/types';

import { enableYandexCounters } from 'shared/metrics/yandex';
import { currentLocale } from 'shared/l10n';
import { getConfigString } from 'shared/firebase';

import { defaultTimeZone } from 'f/settings/timezone-list';
import { getLocalTimezoneId, getLocalTimezoneOffset } from 'f/settings/timezone';

import { baseFetchUtasks } from './base-client';
import type { RawBaseClient } from './base-client';

const tokenKey = 'token';
const tokenExpKey = 'token-expires-at';
export const devModeKey = 'dev-mode';
export const startParamKey = 'start-key';

interface JwtData {
  exp: number;
  sub: string;
}

export const getLocalToken = () => {
  const expires = Number(sessionStorage.getItem(tokenExpKey) ?? undefined);

  if (!isNaN(expires) && expires <= new Date().getTime()) {
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenExpKey);

    return undefined;
  }

  return sessionStorage.getItem(tokenKey) ?? undefined;
};

export const setLocalToken = (value: string, expiresInSeconds: number) => {
  sessionStorage.setItem(tokenKey, value);
  sessionStorage.setItem(tokenExpKey, String(expiresInSeconds * 1000));
};

let tokenPromise: Promise<string | undefined> | null = null;

const sendAuthRequest = (client: RawBaseClient) => (
  enableYandexCounters(),

  import.meta.env.DEV && (sessionStorage.getItem(devModeKey) || !WebApp.initData)
    ? client.POST('/api/auth/test/{id}', {
      params: {
        path: { id: WebApp.initDataUnsafe.user?.id ?? -1 },
        query: {
          timeZone: getLocalTimezoneOffset(),
          locale: currentLocale().language,
        },
      },
    })
    : getConfigString('hardcodeTimezone')
      .then(JSON.parse)
      .catch(() => false)
      .then(shouldSetTimezone => (
        client.POST('/api/auth', {
          params: {
            query: {
              initData: WebApp.initData,
              timeZone: getLocalTimezoneOffset(),
              timeZoneId: shouldSetTimezone ? getLocalTimezoneId() : defaultTimeZone,
              projectId: sessionStorage.getItem(startParamKey) || undefined,
              locale: currentLocale().language,
            },
          },
        })
      ))
);

export const fetchToken = async () => {
  return getLocalToken() ?? (
    tokenPromise ??= (
      baseFetchUtasks
        .then(sendAuthRequest)
        .then(r => r.data)
        .then(tap(token => {
          if (!token) {
            return;
          }

          const { exp: expires } = JSON.parse(window.atob(token.split('.')[1])) as JwtData;
          setLocalToken(token, expires);
        }))

        // TODO: handle error properly
        .catch(() => undefined)
    )
  );
};

const createAuthHeader = (token?: string): {
  Authorization?: string;
} => token ? { 'Authorization': `Bearer ${token}` } : {};

export const getAutorizedHeaders = (
  withHeaders?: Record<string, string>,
  getAuthHeader = () => fetchToken().then(createAuthHeader)
) => getAuthHeader().then(auth => merge(auth, withHeaders));

export const enableDevModeAuth = (spyId = NaN) => {
  console.log('SPY DEV MODE ENABLED');

  sessionStorage.removeItem(tokenKey);
  sessionStorage.removeItem(tokenExpKey);
  sessionStorage.setItem(devModeKey, 'true');

  setInitDataUserId(isNaN(spyId) ? -1 : spyId);

  function setInitDataUserId(id: number) {
    const tgInitParamsKey = '__telegram__initParams';
    const safeParse = <T>(str?: string | null) => str == null ? null : JSON.parse(str) as Exclude<T, undefined | null>;

    const initParams = safeParse<{
      tgWebAppData: string;
    }>(sessionStorage.getItem(tgInitParamsKey));

    try {
      if (initParams) {
        const tgWebAppParams = new URLSearchParams(initParams.tgWebAppData);

        const userData = safeParse<WebAppInitData['user']>(tgWebAppParams.get('user'));

        tgWebAppParams.set('user', JSON.stringify(Object.assign({}, userData, { id })));

        sessionStorage.setItem(tgInitParamsKey, JSON.stringify({
          ...initParams,
          tgWebAppData: tgWebAppParams.toString(),
        }));
      } else {
        throw null;
      }
    } catch {
      console.error('Failed to enable dev mode: no init data provided! Set __telegram__initParams in session storage!');
    }
  }
};
