import WebApp from 'tma-dev-sdk';
import { createRenderEffect, mergeProps, onCleanup, onMount } from 'solid-js';
import type { AsyncStorage } from '@solid-primitives/storage';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import tinycolor from '@ctrl/tinycolor';


export interface BackButtonProps {
  isVisible?: boolean;
  onClick?: () => void;
}

let isBackButtonShown = false;

WebApp.BackButton.onClick(() => {
  // TODO: remove this crutch
  // Allows subscription result pages to redirect to home instead of literally back
  if (!/\/subscription\/(fail|success)/.test(location.pathname)) {
    history.back();
  }
});

export function BackButton(_props: BackButtonProps) {
  const props = mergeProps({ isVisible: true }, _props);

  createRenderEffect(() => {
    if (props.isVisible !== false) {
      isBackButtonShown = true;
      WebApp.BackButton.show();
    } else {
      isBackButtonShown = false;
      WebApp.BackButton.hide();
    }
  });

  const dispatch = createEventDispatcher(props);

  function onClick() {
    dispatch('click');
  }

  onMount(() => {
    if (props.isVisible) {
      isBackButtonShown = true;
      WebApp.BackButton.show();
    }

    WebApp.BackButton.onClick(onClick);
  });

  onCleanup(() => {
    WebApp.BackButton.offClick(onClick);

    isBackButtonShown = false;

    setTimeout(() => {
      if (!isBackButtonShown) {
        WebApp.BackButton.hide();
      }
    }, 10);
  });

  return <></>;
}

export interface MainButtonProps {
  isVisible?: boolean,
  disabled?: boolean,
  showProgress?: boolean,
  backgroundColor?: string,
  textColor?: string,
  text?: string,
  onClick?: () => void;
}

const mainButton = WebApp.MainButton;

export const getCssVariable = (name: string, defaultName: string) => {
  const style = getComputedStyle(document.documentElement);
  const value = style.getPropertyValue(name);

  return (
    !value
      ? style.getPropertyValue(defaultName)
      : value
  );
};
export const getCssColorVariable = (name: string, defaultName: string) => {
  return tinycolor(
    getCssVariable(name, defaultName)
  );
};

export function resetMainButtonColor() {
  const defaultButtonColor = getCssColorVariable(
    '--tg-theme-button-color',
    '--default-tg-theme-button-color'
  );

  mainButton.setParams({ color: defaultButtonColor.toHexString() });

  return defaultButtonColor;
}

export function MainButton(props: MainButtonProps) {
  const hintColor = () => getCssColorVariable(
    '--tg-theme-hint-color',
    '--default-tg-theme-hint-color'
  );

  createRenderEffect(() => {
    mainButton.setParams({
      is_visible: props.isVisible !== false,
      color: props.backgroundColor,
      text_color: props.textColor,
      text: props.text,
    });
  });

  let color = mainButton.color;

  createRenderEffect(() => {
    if (props.disabled) {
      color = mainButton.color;
      mainButton.disable();
      mainButton.setParams({ color: hintColor().toHexString() });
    } else {
      mainButton.enable();
      if (!props.backgroundColor) {
        resetMainButtonColor();
      } else {
        mainButton.setParams({ color: props.backgroundColor ?? color });
      }
    }
  });

  createRenderEffect(() => {
    if (props.showProgress) {
      mainButton.showProgress();
    } else {
      mainButton.hideProgress();
    }
  });

  const dispatch = createEventDispatcher(props);

  function onClick() {
    dispatch('click');
  }

  onMount(() => {
    if (props.isVisible) {
      mainButton.show();
    }

    mainButton.onClick(onClick);
  });

  onCleanup(() => {
    mainButton.offClick(onClick);

    mainButton.hide();
  });

  return <></>;
}

export const CloudStorage: AsyncStorage = {
  setItem(key, value) {
    if (value === undefined) {
      return this.removeItem(key);
    }

    localStorage.setItem(key, value);
    return new Promise((res, rej) => (
      WebApp.CloudStorage.setItem(key, value, (err, result) => err || !result ? rej(err) : res())
    ));
  },
  getItem(key) {
    return new Promise((res, rej) => (
      WebApp.CloudStorage.getItem(key, (err, result) => (
        err && !localStorage.getItem(key)
        ? rej(err)
        : res(
          result != null
          ? (localStorage.setItem(key, result), result)
          : (localStorage.getItem(key) ?? null)
        )
      ))
    ));
  },
  removeItem(key) {
    localStorage.removeItem(key);
    return new Promise((res, rej) => (
      WebApp.CloudStorage.removeItem(key, (err, result) => err || !result ? rej(err) : res())
    ));
  },
  clear() {
    const keys = new Promise<string[] | undefined>((res, rej) => (
      WebApp.CloudStorage.getKeys((err, keys) => err ? rej(err) : res(keys))
    ));

    localStorage.clear();

    return new Promise((res, rej) => keys.then(keys => keys && (
      WebApp.CloudStorage.removeItems(keys, (err, result) => err || !result ? rej(err) : res())
    )));
  },
  key(index) {
    const keys = new Promise<string[] | undefined>((res, rej) => (
      WebApp.CloudStorage.getKeys((err, keys) => err ? rej(err) : res(keys))
    ));

    return keys.then(keys => keys?.[index] ?? null);
  },
  get length() {
    return new Promise<number>((res, rej) => (
      WebApp.CloudStorage.getKeys((err, keys) => err || !keys ? rej(err) : res(keys.length))
    ));
  },
  getAll() {
    const keys = new Promise<string[] | undefined>((res, rej) => (
      WebApp.CloudStorage.getKeys((err, keys) => err ? rej(err) : res(keys))
    ));

    return keys.then(keys => keys && WebApp.CloudStorage.getItems(keys));
  },
};
