// @refresh reload
import WebApp from 'tma-dev-sdk';
import {
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html,
  Link,
  Meta,
  Routes,
  Scripts,
  Title,
  useNavigate,
} from 'solid-start';
import { Suspense, onMount, useContext } from 'solid-js';
import { MetaProvider } from '@solidjs/meta';

if (typeof Object.hasOwn !== 'function') {
  Object.hasOwn = ((dict, key) => Object.prototype.hasOwnProperty.call(dict, key));
}
import { applyAndroidScrollFix } from 'shared/platform';

import { SettingsContext } from 'f/settings/settings.context';

import { displayName, description } from '../package.json';
import './css';

import SW from './SW';

export default function Root() {
  useContext(SettingsContext);
  const navigate = useNavigate();

  // if (!WebApp.initDataUnsafe?.user?.allows_write_to_pm) {
    WebApp.requestWriteAccess();
  // }

  WebApp.expand();
  WebApp.setHeaderColor('secondary_bg_color');

  WebApp.SettingsButton.show();

  onMount(() => {
    document.documentElement.style.colorScheme = WebApp.colorScheme;
    WebApp.SettingsButton.show();
    WebApp.SettingsButton.onClick(() => {
      navigate('/profile/');
    });

    applyAndroidScrollFix();
  });

  return (
    <Html lang={WebApp.initDataUnsafe.user?.language_code ?? window.navigator.language}>
      <Head>
        <Title>{displayName}</Title>
        <Meta charset="utf-8" />
        <Meta name="title" content={displayName}/>
        <Meta name="description" content={description}/>
        <Meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
        <Meta name="robots" content="noindex, nofollow" />
        <Link rel="manifest" href={`${import.meta.env.APP_BASE ?? '/'}manifest.json`} />
      </Head>
      <Body>
        <Suspense>
          <ErrorBoundary>
            <MetaProvider>
              <Routes>
                <FileRoutes />
              </Routes>
            </MetaProvider>
          </ErrorBoundary>
        </Suspense>
        <Scripts />
        <SW />
      </Body>
    </Html>
  );

}
