import WebApp from 'tma-dev-sdk';
import { get, set } from 'solid-utils/access';
import { createSignal } from 'solid-js';
import { cachedIntl, defaultProcessors } from 'intl-schematic/processors';
import { makePersisted } from '@solid-primitives/storage';
import { createLocaleResource as CLR } from '@intl-schematic/solid';

import { isDateEqual, today as _today, tomorrow as _tomorrow, yesterday as _yesterday, relativeDate, hasTime, hasNoTime, afterTomorrow as _afterTomorrow } from 'shared/units/date';

import { getCurrentTimezoneId } from 'f/settings/timezone';
import { getCurrentHourCycle } from 'f/settings/hour-cycle';

import { CloudStorage } from 'shared/ui/telegram';

const localeKey = 'locale';

// CloudStorage backs up to LocalStorage,
// so we can safely assume user locale is stored there most of the times
const clientLocale = localStorage.getItem(localeKey)
  || WebApp.initDataUnsafe.user?.language_code
  || window.navigator.language;

const locale = makePersisted(createSignal(
  new Intl.Locale(clientLocale),
  { equals: (prev, next) => prev.baseName == next.baseName }
), {
  name: localeKey,
  storage: CloudStorage,
  serialize(data) {
    return data.baseName;
  },
  deserialize(data) {
    try {
      return new Intl.Locale(data);
    } catch {
      return new Intl.Locale(clientLocale);
    }
  },
});

/**
 * Accessor for a current browser language
 */
export const currentLocale = () => get(locale);

export const setCurrentLocale = (value: string | Intl.Locale) => set(locale, new Intl.Locale(value));

const convertDateString = (dateStr: string) => new Date(dateStr);

const addSettings = (options?: Intl.DateTimeFormatOptions, timezoneOverride?: string) => ({
  ...options,
  timeZone: timezoneOverride ?? getCurrentTimezoneId(),
  hourCycle: getCurrentHourCycle(),
});

const formatWithTimeZone = (
  format: (value: string | Date, optionsOverride?: Intl.DateTimeFormatOptions) => string,
  convert: (date: string) => Date,
) => (...args: Parameters<typeof format>) => {
  const [input, optionsOverride] = args;
  const date = typeof input === 'string' ? convert(input) : input;

  return format(input, addSettings(optionsOverride, hasNoTime(date) ? 'UTC' : undefined));
};

export const createLocaleResource = CLR(currentLocale, {
  ...defaultProcessors,

  date: cachedIntl(
    Intl.DateTimeFormat,
    convertDateString,
    { format: formatWithTimeZone }
  ),

  'relative-date': cachedIntl(
    Intl.DateTimeFormat,
    convertDateString,
    {
      // TODO: consider memoization
      format: format => (input: {
        today: string;
        tomorrow: string;
        afterTomorrow?: string;
        yesterday: string;
        template: string
        date: string | Date;
      } & {
        [delta: number]: string;
      }, optionsOverride?: Intl.DateTimeFormatOptions) => {
        const date = new Date(input.date);

        const options = addSettings(optionsOverride, hasNoTime(date) ? 'UTC' : undefined);

        const today = _today();
        const tomorrow = _tomorrow();
        const yesterday = _yesterday();
        const afterTomorrow = _afterTomorrow();

        const customDeltas = Object.keys(input)
          .map(Number)
          .filter(x => !isNaN(x))
          .map(x => ({ delta: x, date: relativeDate(x)(today) }));

        const getDelta = () => {
          const delta = customDeltas.find(value => isDateEqual(date, value.date))?.delta;

          return delta && delta in input ? input[delta] : undefined;
        };

        const shouldAddTime = (
          (isDateEqual(date, today) && input.today) ||
          (isDateEqual(date, tomorrow) && input.tomorrow) ||
          (isDateEqual(date, yesterday) && input.yesterday) ||
          (isDateEqual(date, afterTomorrow) && input.afterTomorrow) ||
          getDelta()
        ) && hasTime(date);

        const result = format((
            isDateEqual(date, today) ? input.today
          : isDateEqual(date, tomorrow) ? input.tomorrow
          : isDateEqual(date, yesterday) ? input.yesterday
          : isDateEqual(date, afterTomorrow) ? input.afterTomorrow
          : getDelta()
        ) ?? input.date, options);

        if (shouldAddTime) {
          return `${result}, ${format(date, {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            day: undefined,
            month: undefined,
            year: undefined,
          })}`;
        }

        return result;
      },
    },
  ),
});
