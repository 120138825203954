import { createSignal } from 'solid-js';
import { makePersisted } from '@solid-primitives/storage';

import { currentLocale } from 'shared/l10n';

import { CloudStorage } from 'shared/ui/telegram';

export type HourCycle = Intl.DateTimeFormatOptions['hourCycle'] | undefined;

export const [getCurrentHourCycle, setCurrentHourCycle] = makePersisted(
  createSignal<Intl.DateTimeFormatOptions['hourCycle']>(),
  {
    name: 'hourCycle',
    storage: CloudStorage,
  }
);

export const hourCycles: Array<HourCycle> = [
  undefined,
  'h11',
  'h12',
  'h23',
  'h24',
];

export const getDefaultHourCycle = (): HourCycle => {
  const locale = currentLocale();

  // @ts-expect-error non-standard property
  return Array.isArray(locale.hourCycles) && locale.hourCycles[0] ? locale.hourCycles[0]
    // @ts-expect-error non-standard property
    : typeof locale.getHourCycles === 'function' ? locale.getHourCycles()
    : locale.hourCycle;
};
