import { getNumber } from 'firebase/remote-config';

import { initializeApp, fetchAndActivate, getRemoteConfig, getString, getValue } from './foundation';

if (import.meta.env.DEV) {
  indexedDB.deleteDatabase('firebase_remote_config');
}

const defaultConfig = { apiKey: 'AIzaSyAzXfMjJ8FvhZp3FQATMICH-ndLjXNVUeg', authDomain: 'utasks-prod.firebaseapp.com', databaseURL: 'https://utasks-prod-default-rtdb.europe-west1.firebasedatabase.app', projectId: 'utasks-prod', storageBucket: 'utasks-prod.appspot.com', messagingSenderId: '5984125385', appId: '1:5984125385:web:a25ad2d9018c01ec712818' };

export const firebaseConfig = Promise.resolve<typeof defaultConfig>(
  import.meta.env.APP_FIREBASE_CONFIG
  ? JSON.parse(import.meta.env.APP_FIREBASE_CONFIG)
  : defaultConfig
);

export const app = firebaseConfig.then(initializeApp);
export const config = app.then(getRemoteConfig);
export const configFetching = config.then(fetchAndActivate).then(() => config).catch(() => config);

export const getConfigNumber = (key: string) => configFetching.then(c => getNumber(c, key));
export const getConfigString = (key: string) => configFetching.then(c => getString(c, key));
export const getConfigArray = <T>(key: string) => configFetching.then(c => JSON.parse(getValue(c, key).asString()) as T[]);
