import { get, set } from 'solid-utils/access';
import { createSignal } from 'solid-js';
import { defaultProcessors } from 'intl-schematic/processors';

// TODO: get rid of cyclical dependency
import { hasNoTime } from 'shared/units/date';

import { defaultTimeZone, timeZoneIds } from './timezone-list';
import type { ClientTimeZone } from './settings.adapter';

const currentTimeZone = createSignal<ClientTimeZone>(getTimezone());

// get processor for universal locale (Interlingua - "ia")
const DateProcessor = defaultProcessors.date(new Intl.Locale('ia'));

export const getCurrentTimezone = () => {
  return get(currentTimeZone);
};

export const getCurrentTimezoneId = () => (
  get(currentTimeZone).id === defaultTimeZone ? getLocalTimezoneId() : get(currentTimeZone).id
);

export const setCurrentTimezone = (value: ClientTimeZone) => {
  set(currentTimeZone, value);
  return value;
};

/**
 * Convert a date's timezone back to normal,
 * due to client-only reliance on local timezone.
 *
 * Dates "without a time" (see shared/units/date/hasNoTime for more info)
 * should be shifted back from a pseudo-local timezone (with setting timezone offset)
 * to proper local timezone in order to be parsed correctly later.
 */
export const normalizeDate = (clientDate?: Date | null): Date | null => {
  if (!clientDate) {
    return clientDate ?? null;
  }

  const serverDate = new Date(clientDate);

  if (hasNoTime(clientDate)) {
    // Clear time
    serverDate.setUTCHours(0, 0, 0, 0);
  } else {
    // Otherwise, make sure the date is marked as having time
    serverDate.setUTCSeconds(1, 1);
  }

  return serverDate;
};

/**
 * Convert a date's timezone in a way to account for client-only reliance on local timezone,
 * and dates "without time" (0h:0m:0s:0ms)
 *
 * Dates "without a time" (see shared/units/date/hasNoTime for more info)
 * should be shifted to a pseudo-local timezone (with setting timezone offset)
 * from a local timezone in order to be displayed correctly later.
 */
export const denormalizeDate = (serverDate?: Date | null): Date | null => {
  if (!serverDate) {
    return serverDate ?? null;
  }

  const clientDate = new Date(serverDate);

  if (hasNoTime(clientDate)) {
    clientDate.setUTCSeconds(0, 0);
  } else {
    clientDate.setUTCSeconds(1, 1);
  }

  return clientDate;
};

export function getLocalTimezoneOffset() { return new Date().getTimezoneOffset(); }
export const getCurrentTimezoneOffset = () => (get(currentTimeZone)?.offset ?? getLocalTimezoneOffset());

export function getTimezoneOffset(offsetId?: string) {
  if (!offsetId) {
    return 0;
  }

  if (offsetId === defaultTimeZone) {
    return getLocalTimezoneOffset();
  }

  try {
    const [hours, minutes] = (
      getTZNameFromOptions('longOffset', offsetId)?.slice(3) ?? '0:0'
    ).split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      throw 'invalid timezone value';
    }

    return (-hours * 60) + (minutes ? (hours < 0 ? minutes : -minutes) : 0);
  } catch (error) {
    // Safari can't parse the international `ia` format, so US english is used here
    const localizedTime = new Date(new Date().toLocaleString('en', { timeZone: offsetId }));
    const utcTime = new Date(new Date().toLocaleString('en', { timeZone: 'UTC' }));

    return -Math.round((localizedTime.getTime() - utcTime.getTime()) / (60 * 1000));
  }
}

export function getTimezone(): ClientTimeZone;
export function getTimezone(timeZoneOffset: number, index?: number): ClientTimeZone;
export function getTimezone(timeZoneOffset: number, timeZoneId: string): ClientTimeZone;
export function getTimezone(timeZoneOffset?: number, timeZoneId?: string | number): ClientTimeZone {
  if (typeof timeZoneOffset === 'undefined') {
    return getTimezone(
      getLocalTimezoneOffset(),
      defaultTimeZone
    );
  }

  if (typeof timeZoneId === 'string') {
    timeZoneOffset = getTimezoneOffset(timeZoneId);
  }

  const timeZone = typeof timeZoneId === 'string' ? timeZoneId : typeof timeZoneId === 'number'
    ? timeZoneIds[timeZoneId]
    : getTimeZoneId(timeZoneOffset);

  if (!timeZone) {
    return getTimezone();
  }

  const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
  const offsetName = (timeZoneOffset <= 0 ? '+' : '-') + [
    offsetHours,
    ('00' + (timeZoneOffset % 60)).slice(-2),
  ].join(':');

  return {
    id: timeZone,
    offset: timeZoneOffset,
    offsetName,
  };
}

export function getLocalTimezoneId(): string {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getLocalTimezone = () => getTimezone();

export function getTimeZoneId(timeZoneOffset?: number) {
  return timeZoneIds.find(tz => getTimezoneOffset(tz) === timeZoneOffset);
}

export function getTZNameFromOptions(
  timeZoneName: Intl.DateTimeFormatOptions['timeZoneName'],
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
  locale?: Intl.Locale
) {
  // It's guaranteed to be found
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (locale ? defaultProcessors.date(locale) : DateProcessor)({ timeZoneName, timeZone }, timeZone + timeZoneName)
    .toParts()
    .find(p => p.type === 'timeZoneName')!.value;
}

export function getLocaleTimeZones(locale: Intl.Locale): string[] {
  // @ts-expect-error non-standard property
  return Array.isArray(locale.timeZones) ? locale.timeZones
    // @ts-expect-error non-standard property
    : typeof locale.getTimeZones === 'function' ? locale.getTimeZones() ?? []
    : [];
}
