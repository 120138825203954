import { createContext, createResource } from 'solid-js';

import { currentLocale, setCurrentLocale } from 'shared/l10n';

import { getCurrentTimezone, setCurrentTimezone } from './timezone';
import { fetchSettings } from './settings.network';
import type { ClientSettings } from './settings.adapter';

const serializeSettings = (s: ClientSettings): string => {
  return JSON.stringify({ locale: s.locale.baseName, timeZone: s.timeZone });
};

const deserializeSettings = (s: string): ClientSettings => {
  const deserialized = JSON.parse(s);

  if (
    !deserialized
    || typeof deserialized.locale !== 'string'
    || typeof deserialized.timeZone !== 'object'
  ) {
    return {
      locale: currentLocale(),
      timeZone: getCurrentTimezone(),
    };
  }

  return {
    locale: new Intl.Locale(deserialized.locale),
    timeZone: deserialized.timeZone,
  };
};

const saveSettings = (r: ClientSettings) => {
  localStorage.setItem('settings', serializeSettings(r));

  if (r?.locale) {
    setCurrentLocale(r.locale);
  }

  if (r?.timeZone) {
    setCurrentTimezone(r.timeZone);
  }

  return r;
};

export const SettingsContext = createContext(
  createResource(
    () => fetchSettings().then(saveSettings),
    {
      initialValue: deserializeSettings(localStorage.getItem('settings') || 'null'),
    }
  )
);
